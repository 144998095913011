<template>
  <v-simple-table
    fixed-header
    height="400px"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Item
          </th>
          <th class="text-left">
            Value
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(value, variable) in isDetails.ancient_data" :key="variable"
        >
          <td>{{ variable }}</td>
          <td>{{ value }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
		...mapGetters({
			isDetails: 'informationSystem/isDetails',
		}),
	},
};
</script>

