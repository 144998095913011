<template>
	<div class="mt-1">
		<v-row>
			<v-col cols="6">	
				<v-text-field
					label="Account Name"
					hide-details="auto"
					v-model="isDetails.account_name"
					outlined
					dense
                    readonly
				></v-text-field>
			</v-col>
		</v-row>
		<!-- <v-row>
            <v-col cols="6">
                <v-autocomplete
                    v-if="!isDetails.is_merged"
                    label="Account Manager"
                    outlined
                    dense
                    hide-details="auto"
                    hide-spin-buttons
                    :items="users"
                    item-text="name"
                    return-object
                    @change="getUserId"
                ></v-autocomplete>
            </v-col>
        </v-row> -->
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
		...mapGetters({
			isDetails: 'informationSystem/isDetails',
            users: 'salesProspecting/users',
		}),
	},
    methods: {
        getUserId(i){
            this.$store.commit('informationSystem/SET_USER_ID', i.id)
        }
    }
};
</script>
