<template>
	<div class="mt-1">
		<v-row>
			<v-col cols="6">	
				<v-text-field
					label="Address Line 1"
					hide-details="auto"
					v-model="isDetails.address_line_1"
					outlined
					dense
                    readonly
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">	
				<v-text-field
					label="Address Line 2"
					hide-details="auto"
					v-model="isDetails.address_line_1"
					outlined
					dense
                    readonly
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">	
				<v-text-field
					label="Cellphone Number"
					hide-details="auto"
					v-model="isDetails.cellphone"
					outlined
					dense
                    readonly
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">	
				<v-text-field
					label="City"
					hide-details="auto"
					v-model="isDetails.city"
					outlined
					dense
                    readonly
				></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
		...mapGetters({
			isDetails: 'informationSystem/isDetails',
		}),
	}
};
</script>
