<template>
    <v-expansion-panels class="px-5">
        <v-expansion-panel v-for="(parentItem, parentIndex) in is_branch" :key="parentIndex" class="mb-3">
            <v-expansion-panel-header>
                    {{parentItem.branch_name}}
            </v-expansion-panel-header>
         <v-expansion-panel-content eager>
                        <div class="mt-1"> 
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Address Line 1"
                                        hide-details="auto"
                                        v-model="parentItem.address_line_1"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Address Line 2"
                                        hide-details="auto"
                                        v-model="parentItem.address_line_2"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="API"
                                        hide-details="auto"
                                        v-model="parentItem.api"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="APZ"
                                        hide-details="auto"
                                        v-model="parentItem.apz"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Province"
                                        hide-details="auto"
                                        v-model="parentItem.province"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="City"
                                        hide-details="auto"
                                        v-model="parentItem.city"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Location Code"
                                        hide-details="auto"
                                        v-model="parentItem.location_code"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Landline Number"
                                        hide-details="auto"
                                        v-model="parentItem.landline"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Longtitude"
                                        hide-details="auto"
                                        v-model="parentItem.coordinates.lng"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Latitude"
                                        hide-details="auto"
                                        v-model="parentItem.coordinates.lat"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <h4>Operation Hours</h4>
                                </v-col>
                                <v-col cols="12">
                                    <v-card elevation="0">
                                        <v-tabs
                                            v-model="tab"
                                            >
                                            <v-tab
                                                v-for="day in parentItem.operating_hours"
                                                :key="day.day"
                                                >
                                                <b>{{ day.day }}</b>
                                            </v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                v-for="(day) in parentItem.operating_hours"
                                                :key="day.day"
                                                >
                                                <v-card flat>
                                                    <v-row class="my-3">
                                                        <v-col cols="4">
                                                            <v-text-field
                                                                label="Operating hours from"
                                                                hide-details="auto"
                                                                v-model="day.timeStart"
                                                                outlined
                                                                dense
                                                                readonly
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-text-field
                                                                label="Operating hours to"
                                                                hide-details="auto"
                                                                v-model="day.timeEnd"
                                                                outlined
                                                                dense
                                                                readonly
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <h4>Archive Data</h4>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                <v-simple-table
                                    fixed-header
                                    height="400px"
                                >
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">
                                            Item
                                        </th>
                                        <th class="text-left">
                                            Value
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                        v-for="(value, variable) in parentItem.ancient_data" :key="variable"
                                        >
                                        <td>{{ variable }}</td>
                                        <td>{{ value }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                                </v-col>
                            </v-row>
                                
                        </div>

         </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    
</template>
<script>

import { mapGetters } from 'vuex';
export default {
    data: () => ({ 
        tab: null
    }),
    computed: {
        ...mapGetters({
            is_branch: 'informationSystem/is_branch',
        })
    },
}
</script>
<style>
</style>