<template>
	<div>
		<v-stepper
			class="elevation-0"
			v-model="e6"
			vertical
			>
			<v-stepper-step
				step="1"
                :rules="[() => step1]"
                @click="e6 = 1"
				>
				<span class="stepper-step-proceed">Account Information</span>
                <small v-if="!step1">Account Manager is Required!</small>
			</v-stepper-step>

			<v-stepper-content step="1">
				<v-card
                :elevation="0"
                class="mb-6"
                >
                    <v-form ref="toValidate" v-model="toValidate">
                        <IsAccountInformation/>
                    </v-form>
				</v-card>

				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							@click="e6 = 2"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
				step="2"
                @click="e6 = 2"
				>
				<span class="stepper-step-proceed">Business Address</span>
			</v-stepper-step>

			<v-stepper-content step="2">

				<v-card
                :elevation="0"
                class="mb-6"
                >
					<IsBusinessAddress/>
				</v-card>

				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 3"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
            step="3"
            @click="e6 = 3"
            >
            <span class="stepper-step-proceed">Business Information</span>
			</v-stepper-step>

			<v-stepper-content step="3">
				<v-card
                :elevation="0"
                class="mb-6"
                >
                    <IsBusinessInformation/>
				</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 4"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
            step="4"
            :rules="[() => step4]"
            @click="e6 = 4"
            >
			<span class="stepper-step-proceed">Branch(s)</span>
            <small v-if="!step4">Missing Branch</small>
			</v-stepper-step>

			<v-stepper-content step="4">
				<v-card
					class="mb-1"
					:elevation="0"
					>
					<IsBranch/>
                    <div class="text-center mb-5">
                        <v-pagination
                        v-if="is_branch.length != 0"
                        @input="changePage"
                        v-model="page"
                        :total-visible="5"
                        :length="branchPage.length"
                        ></v-pagination>
                    </div>
				</v-card>
				<v-divider class="my-5"></v-divider>
				<v-row>
					<v-col>
                        <v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
                        <v-btn
							@click="e6 = 5"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
            step="5"
            @click="e6 = 5"
            >
			<span class="stepper-step-proceed">Archive Data</span>
			</v-stepper-step>

			<v-stepper-content step="5">
				<v-card
					class="mb-1"
					:elevation="0"
					>
					<IsAncientData/>
				</v-card>
				<v-row>
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
                        <v-btn
							v-if="!isDetails.is_merged"
							color="success"
							style="float:right;"
                            @click="merge"
							>
							<b>Merge Data</b>
						</v-btn>
					</v-col>
				</v-row>
                
			</v-stepper-content>
		</v-stepper>	
	</div>
</template>

<script>

import IsAccountInformation from './IsAccountInformation.vue';
import IsBusinessAddress from './IsBusinessAddress.vue';
import IsBusinessInformation from './IsBusinessInformation.vue';
import IsAncientData from './IsAncientData.vue';
import IsBranch from './IsBranch.vue';
import { mapGetters } from 'vuex';

export default {
    computed: {
		...mapGetters({
			isDetails: 'informationSystem/isDetails',
			is_branch: 'informationSystem/is_branch',
			user_id: 'informationSystem/user_id',
		}),
	},
    components: {
		IsAccountInformation: IsAccountInformation,
        IsBusinessAddress: IsBusinessAddress,
        IsBusinessInformation: IsBusinessInformation,
        IsAncientData:IsAncientData,
        IsBranch: IsBranch
	},
    data: () => ({ 
		e6: 1,
        users: [],
		page: 1,
		branchPage: [],
        toValidate: true,
        step1: true,
        step4: true
	}),
    async created () {

        this.$store.commit('informationSystem/SET_DEFAULT')

        const res = await this.$store.dispatch('informationSystem/doGetIsDetails', this.$route.params.id)
        if (res.status == 200){
            const payload = res.data.result
            this.$store.commit('informationSystem/SET_IS_DETAILS', payload)
            this.countBranchPage(res.data.result.is_data_location)
            this.decodeBranch(res.data.result.is_data_location.data)
        }


        const resP = await this.$store.dispatch('salesProspecting/doGetlAllUsers')
        if(resP.status == 200){
            resP.data.result.map(item => {
                this.users.push(item)
            })
            this.$store.commit('salesProspecting/SET_SAM_USERS', this.users)
        }

    },
    methods: {
        countBranchPage(e){
			this.page = 1
			this.branchPage = []
			if(e){
				let number = e.last_page
				for (let i = 1; i <= number; i++) {
					this.branchPage.push(i)
				}
			}
		},
		async changePage(e){
            const payload = {
                id : this.$route.params.id,
                page: e
            }
			const res = await this.$store.dispatch('informationSystem/changePageBranch', {payload})
			if(res.status === 200) {
				this.decodeBranch(res.data.result.is_data_location.data)
			}
		},
        decodeBranch(e){
            this.$store.commit('informationSystem/SET_IS_BRANCH', e)
        },
        async merge(){
			this.step1 = true
			this.step4 = true

			const payload = {
				bp_id_no: this.$route.params.id,
				finalPayload: {
					user_id : this.user_id
				}
			}

			const res = await this.$store.dispatch('informationSystem/doMergeData', {payload})
			if (res.status == 200){
				this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Merged Successfully!','messagetype':'success'},{ root: true });
				this.$store.commit('informationSystem/SET_DEFAULT')
				this.$router.push('/is-primary');
			}
        }
    }
}
</script>
