<template>
	<div class="mt-1">
		<v-row>
			<v-col cols="6">	
				<v-text-field
					label="Business Type of Sub Agents"
					hide-details="auto"
					v-model="isDetails.business_type_of_sub_agents"
					outlined
					dense
                    readonly
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">	
				<v-text-field
					label="Government Tax ID"
					hide-details="auto"
					v-model="isDetails.government_tax_id"
					outlined
					dense
                    readonly
				></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
		...mapGetters({
			isDetails: 'informationSystem/isDetails',
		}),
	}
};
</script>
